'use strict';

var ajax = require('../../ajax'),
    image = require('./image'),
    storesDialog = require('../checkout/storesDialog'),
    tooltip = require('../../tooltip'),
    util = require('../../util'),
    wishlist = require('../wishlist'),
    accessibility = require('../../accessibility');

/**
 * @description update product content with new variant from href, load new content to .product-content panel
 * a color change will update the form and do another update for the size selection if the same size is in the size <select>
 * (SFCC back-end logic and Vilebrequin wanted behavior are incompatible)
 * @param {String} href - url of the new product variant
 **/
var hrefForUpdate = null;
var updateContent = function ($swatch, href, isColorUpdate, isFocused) {
    var $wrapper = $swatch.closest('.js-pdp-main'),
        $pdpForm = $wrapper.find('.pdpForm'),
        qty = $pdpForm.find('input[name="Quantity"]').first().val(),
        isCrossSell = $wrapper.hasClass('js-cross-sell-pdp'),
        isStickySize = window.vbqUtils.isSR ? $swatch.hasClass('js-sticky-size-select') : false,
        isUpdateScroll = isStickySize && !isCrossSell,
        // EPU-1 - New PDP design on VBQ - size list logic per brand
        value = $swatch.hasClass('js-swatchanchor') ? (window.vbqUtils.isSR ? $wrapper.find('.js-size-select').first().val() : $swatch.filter('.selected-value').attr('data-variation')) : $swatch.val(),
        params = {
            Quantity: isNaN(qty) ? '1' : qty,
            format: 'ajax',
            productlistid: $pdpForm.find('input[name="productlistid"]').length > 0 ? $pdpForm.find('input[name="productlistid"]').first().val() : '',
            isCrossSell: isCrossSell // EW-130 - CRO - PdP Cross Sell
        };

    if (hrefForUpdate == href) {
        return false;
    }
    hrefForUpdate = href;

    $pdpForm.find('.add-to-cart').attr('disabled', 'disabled');

    window.vbqUtils.progressLoader(true, $wrapper.find('.js-pdp-progress-loader'));

    var $target = isCrossSell ? $wrapper.closest('.product-content').parent() : $wrapper.find('.product-content'),
        url = util.appendParamsToUrl(href, params);

    if (value === null || value === '' || value === undefined) {
        url = util.appendParamsToUrl(url, {forceNoSizeSelected: true});
    }

    if (isColorUpdate) {
        // avoid problem on outlet is the current selected size for the color is offline (there is always one technically)
        var masterCode = $('.js-detailTabModel').html();
        if (masterCode != '' && masterCode != null && masterCode.indexOf('-') != -1) {
            masterCode = masterCode.split('-')[0];
            url = util.removeParamFromURL(url, 'dwvar_' + masterCode + '_size');
        }
    }

    // remove listener to avoid multiple calls
    // EPU-1 - New PDP design on VBQ - size list logic per brand
    if (!window.vbqUtils.isSR) {
        window.vbqUtils.cache.$body.off('change', '.js-size-radio', sizeChange);
    }
    else {
        window.vbqUtils.cache.$body.off('change', '.js-size-select', sizeChange);
    }

    $.ajax({
        url: url
    })
    .done(function (data) {
        $target.html(data);

        // ECM-830 - Sku not added to cart
        // This line is incorrect, as the disable logic is done through the content update itself
        //$('.pdpForm').find('.add-to-cart').removeAttr('disabled');

        // Depending on the page type (cross-sell or quick-view) .js-pdp-main can be inside of the $wrapper element or be a parent of the $wrapper element
        $wrapper = isCrossSell ? $target.find('.js-pdp-main') : $target.closest('.js-pdp-main');

        // make sure the loader is shown
        window.vbqUtils.progressLoader(true, $wrapper.find('.js-pdp-progress-loader'));

        storesDialog.PDPInit($wrapper);
        // ECM-710 - Add to cart form being in cache - Update the images only on color updates
        if (isColorUpdate) {
            image.replaceImages($wrapper);
        }

        // EADA-89 - color swatch with radio button behavior
        // Init fake radio groups according to the ADA Compliance: color switch
        accessibility.fakeRadioGroupInit($wrapper);

        // Scroll in the color list to selected color item
        window.vbqUtils.colorListSet($wrapper, true);

        tooltip.init();

        // EPU-1
        window.vbqUtils.cache.$pdpMobileAddToCartStickyTrigger = $('#js-mobileAddToCartStickyTrigger');

        //ERF-58 Product detail page - Sticky product form
        window.vbqUtils.cache.$document.trigger('pdpTopUpdate');

        // ECM-710 - Add to cart form being in cache - Update the images only on color updates
        if (isColorUpdate || params.isCrossSell) {
            if (window.vbqUtils.setupProductPrimaryImage !== undefined) {
                window.vbqUtils.setupProductPrimaryImage($wrapper);
            }
        }

        if (isStickySize || isCrossSell) {
            $wrapper.find('.js-cross-sell-preorder-msg').removeClass('hidden');
        }

        // EW-52 - PDP Revision - update the detail tab data when the color or size changes
        var productModel = $wrapper.find('.js-productModel').val(),
            productColor = $wrapper.find('.js-productColor').val();
        if (productModel !== '') {
            $wrapper.find('.js-detailTabModel').html(productModel);
        }
        if (productColor !== '') {
            $wrapper.find('.js-detailTabColor').html(productColor);
        }

        //ERB-52 International Shipping
        window.vbqUtils.priceDisplay.refresh();

        // EPM-2 - Monogramming - Update the PDP price when a monogram is in session, same logic is present in index.js for monogram activation
        var $productContent = $wrapper.find('.product-content');
        if ($productContent.find('.monogram-not-set').hasClass('visually-hidden')) { // monogram option possible and already set
            /* old logic where the display is product price + option price (290$ + 45$)
            var $optionPrice = $productContent.find('.monogram-not-set').find('.js-priceDisplay').clone(), // first clone the price of the option, which will have been updated for display (pricedisplay.js logic)
                $span = $('<span>').addClass('option-price').html('+&nbsp;').append($optionPrice); // surround it with another span for easier targeting
            $productContent.find('.product-detail-price') // normal and sticky form prices
                .find('.option-price').remove().end() // clean up option price if already present, security
                .append($span); // add the option price beside the product price
            */
            // new logic where only one price is displayed (290$ + 45$ -> 335$)
            var optionPrice = $productContent.find('.monogram-not-set').find('.price-display-component').attr('data-pricevalue'),
                $productPrice = $productContent.find('.product-detail-price').find('.price-display-component'); // normal and sticky form prices
            $productPrice.attr('data-originalpricevalue', $productPrice.attr('data-pricevalue'));
            $productPrice.attr('data-pricevalue', parseFloat($productPrice.attr('data-pricevalue')) + parseFloat(optionPrice));
            $productPrice.addClass('js-forcedPriceDisplay'); // for non converted prices (see pricedisplay.js)
            window.vbqUtils.priceDisplay.refresh();

            // hide the Apple Pay button && click and collect button if present
            $wrapper.find('.js-product-btn-options').addClass('hidden');
        }

        // EPU-1 - New PDP design on VBQ - size list logic per brand
        if (!window.vbqUtils.isSR) {
            $('.product-detail-price').has('.gl_price-beforePromo').addClass('promotional-price');
        }

        $wrapper.find('.js-updateImages').remove();
        $('#js-updateCrossSell').remove();

        // EAFL-3 - AGEC traceability information tab on PDP
        var $updateTraceability = $wrapper.find('.js-updateTraceability');
        if ($updateTraceability.length && $updateTraceability.find('#js-pdptraceabilityheader').length) {
            var $pdpTabs = $wrapper.find('#pdpTabs');
            if ($pdpTabs.length) {
                //remove existing
                $pdpTabs.find('#js-pdptraceabilityheader').remove();
                $pdpTabs.find('#traceability-tab').remove();

                // append updated content
                $pdpTabs.append($updateTraceability.html());

                $pdpTabs.customAccordion('destroy');
                $pdpTabs.customAccordion();
            }
        }
        $updateTraceability.remove();

        // on color change the size <select> may have no value, ensure size related elements are hidden/shown (does not override the monogram button logic)
        var triggerChange = false;
        if (!isColorUpdate && value) {
            // no color change, the <select> does not have the requested size selected, select it and update the buttons and messages
            // EPU-1 - New PDP design on VBQ - size list logic per brand
            if (!window.vbqUtils.isSR) {
                $wrapper.find('.js-size-radio').filter('[data-value="'+ value +'"]').addClass('selected') // update the radio matching the value
                    .find('.js-swatchanchor').addClass('selected-value'); // and its swatch
            }
            else {
                $wrapper.find('.js-size-select').val(value);
            }
            $wrapper.find('.add-to-cart').removeClass('disabled');


            $wrapper.find('#js-sizeSelectedUnavailable').removeClass('hidden');
            $wrapper.find('.js-monogram-set').removeClass('hidden');
            $wrapper.find('.js-monogram-notset').removeClass('hidden');
        }
        else if (isColorUpdate && value) {
            // EPU-1 - New PDP design on VBQ - size list logic per brand
            if (!window.vbqUtils.isSR) {
                if ($wrapper.find('.js-size-radio').filter('[data-value="'+ value +'"]').length) {
                    triggerChange = true;
                }
                else {
                    $wrapper.find('#js-noSizeSelectedDefaultButton').removeClass('hidden');
                    $wrapper.find('.js-monogram-set').addClass('hidden');
                    $wrapper.find('.js-monogram-notset').addClass('hidden');
                    $wrapper.find('.js-monogramMessage').addClass('hidden');
                }
            }
            else {
                // update mobile/desktop size dropdown, but only if the previous size exists (color change case)
                if ($wrapper.find('.js-size-select').find('option[value="' + value + '"]').length) {
                    triggerChange = true;
                }
                else {
                    // select the empty size value for the <select> and hide the monogram elements
                    $wrapper.find('.js-size-select').val('');
                    $wrapper.find('#js-noSizeSelectedDefaultButton').removeClass('hidden');
                    $wrapper.find('.js-monogram-set').addClass('hidden');
                    $wrapper.find('.js-monogram-notset').addClass('hidden');
                    $wrapper.find('.js-monogramMessage').addClass('hidden');
                }
            }
        }
        else {
            $wrapper.find('#js-noSizeSelectedDefaultButton').removeClass('hidden');
            $wrapper.find('.js-monogram-set').addClass('hidden');
            $wrapper.find('.js-monogram-notset').addClass('hidden');
            $wrapper.find('.js-monogramMessage').addClass('hidden');
        }

        // EWR20 - force display of the cross-sell button (fade-in using transaction) and set the listener again
        if (!isCrossSell) {
            $('.js-crossSellLink').on('click keydown', window.vbqUtils.crossSellButtonAction);
            initCrossSellCarousel();
        }

        // EPO20-14 - PDP add-to-cart form impacts
        if (isUpdateScroll) {
            window.vbqUtils.cache.$html.animate({scrollTop: window.vbqUtils.cache.$body.height() - ($wrapper.find('.pdpForm').length ? $wrapper.find('.pdpForm').offset().top : 0)}, 100);
        }

        // update product detail gtm event for quickview and pdp
        // ECM24-8 - Datalayer update (GA4 GTM)
        // window.vbqUtils.gtmUtils.addProductDetail();

        // make sure the loader is hidden
        window.vbqUtils.progressLoader(false, $wrapper.find('.js-pdp-progress-loader'));

        // add listener again
        // EPU-1 - New PDP design on VBQ - size list logic per brand
        if (!window.vbqUtils.isSR) {
            window.vbqUtils.cache.$body.on('change', '.js-size-radio', sizeChange);
        }
        else {
            window.vbqUtils.cache.$body.on('change', '.js-size-select', sizeChange);
        }

        if (triggerChange) {
            // necessary to be sure the correct size is selected when changing color
            // keep focus on color switcher, param isFocused = 'color'
            // EPU-1 - New PDP design on VBQ - size list logic per brand
            if (!window.vbqUtils.isSR) {
                $wrapper.find('.js-size-radio').filter('[data-value="'+ value +'"]').find('.js-swatchanchor').trigger('click', 'color');
            }
            else {
                $wrapper.find('.js-size-select').val(value).trigger('change', 'color');
            }
        }

        // EADA21-33 - ADA - #85 and 88 - Apply focus back on the size select drop down within the modal after the page refreshes.
        if (isFocused) {
            if (isColorUpdate || isFocused == 'color') {
                $wrapper.find('.js-color-radio.selected').focus();
            }
            else {
                // EPU-1 - New PDP design on VBQ - size list logic per brand
                if (!window.vbqUtils.isSR) {
                    $wrapper.find('.js-size-radio.selected').focus();
                }
                else {
                    $wrapper.find('.js-size-select').focus();
                }
            }
        }

        wishlist.updateWishlistIcons();

        // EPU-1
        if (!window.vbqUtils.isSR) {
            $('#js-crossSellBlock').appendTo(window.vbqUtils.breakpoint.is('fromDesktop') ? '#js-crossSellBlockDesktop' : '#js-crossSellBlockMobile');

            if (!isCrossSell && $('#QuickViewDialog').length === 0 && $('.netreviews_review_rate_and_stars').length) {
                $('#js-reviewsSummaryAjax').html($('#js-reviewsSummary').clone().removeAttr('id')).removeClass('hidden');
                $('#js-reviewsStars').html($('#js-reviewsSummary').find('.netreviews_review_rate_and_stars').clone());
            }
        }

        hrefForUpdate = null;
    })
    .fail(function () {
        // EPU-1 - New PDP design on VBQ - size list logic per brand
        if (!window.vbqUtils.isSR) {
            window.vbqUtils.cache.$body.off('change', '.js-size-radio', sizeChange);
            window.vbqUtils.cache.$body.on('change', '.js-size-radio', sizeChange);
        }
        else {
            window.vbqUtils.cache.$body.off('change', '.js-size-select', sizeChange);
            window.vbqUtils.cache.$body.on('change', '.js-size-select', sizeChange);
        }
        window.vbqUtils.progressLoader(false, $wrapper.find('.js-pdp-progress-loader'));

        hrefForUpdate = null;
    });
};

function sizeChange(e, isFocused) {
    var $this = $(e.currentTarget),
        $wrapper = window.vbqUtils.isSR ? $this.closest('.size-list') : $this.closest('.size-radios'),
        $selectedOption = window.vbqUtils.isSR ? $this.find('option:selected') : $this.find('.selected-value'),
        type = $selectedOption.attr('data-variation-type');

    //EWR20-98 Size variations dropdown
    $wrapper.removeClass('gl_has-error');

    //ERF-58 Product detail page - Size chart section highlight listener
    window.vbqUtils.cache.$document.trigger('variationchanged', [type, $this]);

    if (window.vbqUtils.isSR) {
        var url = $selectedOption.data('url');

        // ECM-710 - Add to cart form being in cache - Update the images only on color updates
        updateContent($this, url, type == 'color', (typeof isFocused === 'undefined' ? true : isFocused));
    }
}

var initListeners = function () {
    // EADA-89 - color swatch with radio button behavior: attach logic after fake radio state updates
    // EPU-1 - also for size swach
    window.vbqUtils.cache.$body.on('afterFakeRadioStateUpdated', '.js-pdp-main .product-detail-container .js-color-radio, .js-pdp-main .product-detail-container .js-size-radio', function (e, configs) {
        // call ajax refresh on keyboard select action
        // "click" is necessary to support JAWS screenreader, which triggers nonstandard events and do not propagate them
        if ((configs.typeEvent == 'keydown' && configs.actionEvent == 'select') || configs.typeEvent == 'click') {
            $(this).find('.js-swatchanchor').trigger('customClick');
        }
    });

    // click on swatch - should replace product content with new variant
    window.vbqUtils.cache.$body.on('click customClick', '.js-pdp-main .product-detail-container .js-swatchanchor', function (e) {
        e.preventDefault();
        e.stopPropagation(); // prevent conflict with fake radio button wrapper .js-color-radio
        var $this = $(this),
            type = $this.data('variation-type');

        if ($this.parents('li').hasClass('unselectable')) { return; }

        if (!window.vbqUtils.isSR && type == 'size') {
            // the event is on the <span> inside the <li role="radio"> for list of size radios
            // update the value and trigger the refresh
            // note: the <li> is already updated by ADA logic (.selected)
            $this.closest('.js-size-list').find('.selected-value').removeClass('selected-value');
            $this.addClass('selected-value');

            //ERF-58 Product detail page - Size chart section highlight listener
            window.vbqUtils.cache.$document.trigger('variationchanged', [type, $this]);

            // force the style guide update as the change event is not triggered for radio buttons
            $this.closest('.js-size-radio').trigger('change');
        }
        else {
            //ERF-58 Product detail page - Size chart section highlight listener
            window.vbqUtils.cache.$document.trigger('variationchanged', [type, $this]);
        }

        // ECM-710 - Add to cart form being in cache - Update the images only on color updates
        updateContent($this, $this.attr('data-href'), type == 'color', true);
    });

    // EPU-1 - New PDP design on VBQ - size list logic per brand
    if (!window.vbqUtils.isSR) {
        window.vbqUtils.cache.$body.on('change', '.js-size-radio', sizeChange);
    }
    else {
        window.vbqUtils.cache.$body.on('change', '.js-size-select', sizeChange);
    }

    // EWR20-506 - [Prod] SOLD OUT- English- PDP whereas stocks are available other languages
    // force form refresh in order to have the correct inventory related information for each size
    // timeout needed to avoid scrollbar jumps
    if ($('#QuickViewDialog').length === 0) {
        window.setTimeout(function () {
            // EPU-1 - New PDP design on VBQ - size list logic per brand
            if (!window.vbqUtils.isSR) {
                $('.js-size-radio').find('.js-swatchanchor').first().each(function () {
                    var $radio = $(this);
                    // initial PDP form load: isFocused = false
                    updateContent($radio, $radio.data('href'), false, false);
                });
            }
            else {
                $('.js-size-select').filter(':visible').each(function () {
                    var $select = $(this),
                        url = $select.find('option').last().data('url');
                    // initial PDP form load: isFocused = false
                    updateContent($select, url, false, false);
                });
            }
        }, 50);
    }
};

var initCrossSellCarousel = function () {
    var $carousel = $('#js-crossSellBikiniCarousel'),
        $tiles = $carousel.find('.cross-sell-bikini-tile');

    window.vbqUtils.breakpoint.set([
        {
            media: window.vbqUtils.breakpoint.getMediaQuery('mobilePortrait'),
            enter: function () {
                if ($tiles.length > 2) {
                    $carousel.slick('unslick').slick({
                        slidesToShow: 2,
                        dots: false,
                        arrows: true,
                        swipe: true
                    });

                    $carousel.closest('.cross-sell-bikini-slider-wrapper').css('overflow', 'visible');
                }
                else {
                    $carousel.slick('unslick');
                    $carousel.closest('.cross-sell-bikini-slider-wrapper').css('overflow', 'hidden');

                    // re-init keyboard accessibility on tiles if carousel doesn't init
                    $tiles.attr('tabindex', '0');
                }
            }
        },
        {
            media: window.vbqUtils.breakpoint.getMediaQuery('mobileLandscape'),
            enter: function () {
                if ($tiles.length > 3) {
                    $carousel.slick('unslick').slick({
                        slidesToShow: 3,
                        dots: false,
                        arrows: true,
                        swipe: true
                    });

                    $carousel.closest('.cross-sell-bikini-slider-wrapper').css('overflow', 'visible');
                }
                else {
                    $carousel.slick('unslick');
                    $carousel.closest('.cross-sell-bikini-slider-wrapper').css('overflow', 'hidden');

                    // re-init keyboard accessibility on tiles if carousel doesn't init
                    $tiles.attr('tabindex', '0');
                }
            }
        },
        {
            media: window.vbqUtils.breakpoint.getMediaQuery('tabletAndDesktop'),
            enter: function () {
                if ($tiles.length > 4) {
                    $carousel.slick('unslick').slick({
                        slidesToShow: 4,
                        dots: false,
                        arrows: true,
                        swipe: true
                    });

                    $carousel.closest('.cross-sell-bikini-slider-wrapper').css('overflow', 'visible');
                }
                else {
                    $carousel.slick('unslick');
                    $carousel.closest('.cross-sell-bikini-slider-wrapper').css('overflow', 'hidden');

                    // re-init keyboard accessibility on tiles if carousel doesn't init
                    $tiles.attr('tabindex', '0');
                }
            }
        }
    ]);
};

module.exports = function () {
    initListeners();
};
